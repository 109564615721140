'use strict';

// Массив из классов на свг карте (для Филиппин принудидельно добавлен path, так как там несколько элементов объединены в контейнер g)
const mapItems = ['#map-item-np', '#map-item-in', '#map-item-pk', '#map-item-bg', '#map-item-ph path', '#map-item-de', '#map-item-ch', '#map-item-uz', '#map-item-fr', '#map-item-ng', '#map-item-es', '#map-item-gh', '#map-item-kz', '#map-item-pl', '#map-item-fi'];

function idx(x,y) {
    return mapItems[$(x).index(y)];
}
//При наведение на класс .country-item берется его индекс и ищется
// элемент с таким же индексом в массиве с классами свг карты, затем этому классу из массива добавляется класс .fill,
// при отведении курсора класс убирается
$('#section-map .country-item').mouseenter(
    function () {
        for (const mapItem of mapItems) {
            $(mapItem).addClass('map-item-transition')
        };
        $(idx('.country-item', this)).addClass('fill');
    });

$('.country-item').mouseleave(
    function () {
        for (const mapItem of mapItems) {
            $(mapItem).removeClass('map-item-transition')
        };
        $(idx('.country-item', this)).removeClass('fill')
    });
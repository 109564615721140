$(document).ready(() => {
        if (window.ffScroll != null) {
            console.warn('FFScroll is already initialized');
            return;
        }

        window.ffScroll = () => {
            // здесь функция скролла наверх;
            $('html, body').animate({scrollTop: 0}, 150);
            return false;
        }
    }
)
'use strict';

if (navigator.platform != null) {
    let platform = navigator.platform;
    let iOs = ['iPhone', 'iPad', 'iPod']

    window.mobileApps = function() {
        if (iOs.includes(platform)) {
            $('.store').addClass("show");
        } else if (platform === 'Android') {
            $('.gallery').addClass("show");
            $('.play').addClass("show");
        } else if (platform.includes('Linux')) {
            $('.gallery').addClass("show");
            $('.play').addClass("show");
        } else {
            $('.store').addClass("show");
            $('.gallery').addClass("show");
            $('.play').addClass("show");
        }
    }
}


